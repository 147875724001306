<template>
  <div>
    <h3>
      Spot Search
      <hr />
    </h3>
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">Search Parameters</div>
      </div>
      <div class="card-body">
        <ValidationObserver ref="spotSearchForm">
          <form @submit.prevent="searchSpots(true)">
            <div class="row mb-3">
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  name="Park"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                  rules="required"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="spotSearchPark"
                  >
                    Park
                    <span class="error">*</span>
                  </label>
                  <multiselect
                    v-model="search.selectedPark"
                    label="name"
                    track-by="id"
                    :multiple="false"
                    id="spotSearchPark"
                    ref="spotSearchPark"
                    :clear-on-select="false"
                    :preserve-search="true"
                    open-direction="bottom"
                    placeholder="Select Park"
                    :options="parks"
                    @open="openedParks"
                    @select="resetOtherFields"
                    :loading="loadingParks"
                    v-bind="ariaInput"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="multiselect__tag" :key="option.id">
                        <span>{{ option.name }}</span>
                        <i
                          tabindex="1"
                          @keypress.enter.prevent="remove(option)"
                          @mousedown.prevent="remove(option)"
                          class="multiselect__tag-icon"
                        ></i>
                      </span> </template
                  ></multiselect>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
              </div>
              <div class="col-md-6 col-sm-12">
                <label @click="$refs.select.focus()" for="spotSearchArea"
                  >Campgrounds</label
                >
                <multiselect
                  v-model="search.selectedAreas"
                  label="name"
                  track-by="id"
                  :multiple="true"
                  id="spotSearchArea"
                  ref="spotSearchArea"
                  :clear-on-select="false"
                  :preserve-search="true"
                  open-direction="bottom"
                  placeholder="Select Area"
                  :options="areas"
                  @open="openedAreas"
                  :loading="loadingAreas"
                  :disabled="parkNotSelected"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="multiselect__tag" :key="option.id">
                      <span>{{ option.name }}</span>
                      <i
                        tabindex="1"
                        @keypress.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon"
                      ></i>
                    </span> </template
                ></multiselect>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6 col-sm-12">
                <label @click="$refs.select.focus()" for="spotSearchLoop"
                  >Loops</label
                >
                <multiselect
                  v-model="search.selectedLoops"
                  label="name"
                  track-by="id"
                  :multiple="true"
                  id="spotSearchLoop"
                  ref="spotSearchLoop"
                  :clear-on-select="false"
                  :preserve-search="true"
                  open-direction="bottom"
                  placeholder="Select Loop"
                  :options="loops"
                  @open="openedLoops"
                  :loading="loadingLoops"
                  :disabled="parkNotSelected"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="multiselect__tag" :key="option.id">
                      <span>{{ option.name }}</span>
                      <i
                        tabindex="1"
                        @keypress.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon"
                      ></i>
                    </span> </template
                ></multiselect>
              </div>
              <div class="col-md-6 col-sm-12">
                <label
                  @click="$refs.select.focus()"
                  for="spotSearchProductClassifications"
                  >Product Classes</label
                >
                <multiselect
                  v-model="search.selectedProductClassifications"
                  label="name"
                  track-by="id"
                  :multiple="true"
                  id="spotSearchProductClassifications"
                  ref="spotSearchProductClassifications"
                  :clear-on-select="false"
                  :preserve-search="true"
                  open-direction="bottom"
                  placeholder="Select Product Classes"
                  :options="productClassifications"
                  @open="openedProductClassifications"
                  :loading="loadingProductClassifications"
                  :disabled="parkNotSelected"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="multiselect__tag" :key="option.id">
                      <span>{{ option.name }}</span>
                      <i
                        tabindex="1"
                        @keypress.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon"
                      ></i>
                    </span> </template
                ></multiselect>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6 col-sm-12">
                <label @click="$refs.select.focus()" for="spotSearchSpotType"
                  >Spot Types</label
                >
                <multiselect
                  v-model="search.selectedSpotTypes"
                  label="name"
                  track-by="id"
                  :multiple="true"
                  id="spotSearchSpotType"
                  ref="spotSearchSpotType"
                  :clear-on-select="false"
                  :preserve-search="true"
                  open-direction="bottom"
                  placeholder="Select Spot Types"
                  :options="spotTypes"
                  @open="openedSpotTypes"
                  :loading="loadingSpotTypes"
                  :disabled="parkNotSelected"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="multiselect__tag" :key="option.id">
                      <span>{{ option.name }}</span>
                      <i
                        tabindex="1"
                        @keypress.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon"
                      ></i>
                    </span> </template
                ></multiselect>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  name="Spot Name"
                  rules="tenantSiteName"
                  id="spotSearchSpotName"
                  placeholder="Enter Spot Name"
                  v-model="search.spotName"
                  :disabled="parkNotSelected"
                />
              </div>
            </div>
            <button
              type="button"
              class="btn btn-primary mr-2 mb-2"
              :disabled="parkNotSelected"
              @click="searchSpots(true)"
            >
              SEARCH
            </button>
            <button
              type="button"
              class="btn btn-secondary mr-2 mb-2"
              @click="reset"
            >
              RESET
            </button>
            <button
              type="button"
              class="btn btn-primary mb-2"
              @click="addSpot"
              :disabled="!checkPermission('ParkManagementSpotSearch')"
            >
              ADD NEW SPOT
              <i class="fa fa-plus-circle" />
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">Spots</div>
      </div>
      <div class="card-body" v-show="spots.length > 0">
        <div class="d-flex mb-2">
          <div class="align-self-center">Items Per Page:</div>
          <div class="ml-2">
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              aria-label="Items Per Page"
            ></b-form-select>
          </div>
        </div>
        <b-table
          ref="table"
          striped
          :fields="fields"
          :items="loadData"
          :current-page="currentPage"
          :per-page="perPage"
          no-local-sorting
          bordered
          sort-icon-left
          stacked="xl"
          small
          class="mt-4"
        >
          <template v-slot:cell(id)="data">
            <button
              class="btn btn-primary btn-sm mr-2 my-1"
              @click="view(data.item.id)"
            >
              View
            </button>
            <button
              class="btn btn-primary btn-sm mr-2 my-1"
              @click="modify(data.item.id)"
            >
              Modify
            </button>
          </template>
        </b-table>
        <div class="d-flex">
          <div class="mr-auto">
            Showing
            {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }} to
            {{ perPage * currentPage - perPage + perPageDisplay }} of
            {{ totalRows }} entries
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
      <div
        class="card-body text-center"
        v-show="spots.length === 0 && !loading"
      >
        <div class="h4 my-3">No Results</div>
      </div>
      <div class="card-body text-center" v-show="loading && spots.length === 0">
        <div class="my-3">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import Multiselect from "vue-multiselect";
import AdminSpotService from "@/services/admin/AdminSpotService.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminSpotSearch",
  title: "Admin - Spot Search",
  mixins: [checkPermissionMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    TextInput,
    Multiselect
  },
  data() {
    return {
      loading: false,
      spots: [],
      currentPage: 1,
      totalRows: 25,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      perPageDisplay: 10,
      fields: [
        { key: "spotName", sortable: true },
        { key: "area", label: "Campground", sortable: true },
        { key: "loop", sortable: true },
        {
          key: "productClassification",
          label: "Product Class",
          sortable: true
        },
        { key: "basePrice", sortable: true, formatter: "formatPrice" },
        {
          key: "maxPeople",
          sortable: true,
          formatter: "formatNumbers",
          label: "People"
        },
        {
          key: "maxVehicles",
          sortable: true,
          formatter: "formatNumbers",
          label: "Vehicle"
        },
        {
          key: "maxVehicleLength",
          label: "Eq Length",
          sortable: true,
          formatter: "formatVehicleLength"
        },
        {
          key: "adaAccessible",
          label: "ADA Accessible",
          sortable: true,
          formatter: "formatBoolean"
        },
        {
          key: "salesChannel",
          label: "Sales Channel",
          sortable: true
        },
        { key: "electricHookup", label: "Electricity Hookup", sortable: true },
        { key: "id", sortable: false, label: "Actions/Options" }
      ],
      search: {},
      parks: [],
      loadingParks: false,
      loops: [],
      loadingLoops: false,
      areas: [],
      loadingAreas: false,
      productClassifications: [],
      loadingProductClassifications: false,
      spotTypes: [],
      loadingSpotTypes: false
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    searchParams() {
      return this.$store.getters["admin/spotSearchParams"];
    },
    parkNotSelected() {
      return (
        this.search.selectedPark === undefined ||
        this.search.selectedPark === null
      );
    }
  },
  methods: {
    searchSpots(refreshTable) {
      this.$store.commit("admin/setSpotSearchParams", {
        ...this.search
      });

      if (refreshTable) {
        this.$refs.table.refresh();
      }
      this.currentPage = 1;
    },
    view(id) {
      this.$router.push(`/admin/spot-detail/${id}?v=true`).catch(() => {});
    },
    modify(id) {
      this.$router.push(`/admin/spot-detail/${id}?v=false`).catch(() => {});
    },
    formatPrice(price) {
      let numPrice = +price;
      return `$${numPrice.toFixed(2)}`;
    },
    formatBoolean(x) {
      if (x === true) return "Yes";
      else return "No";
    },
    formatVehicleLength(length) {
      if (length !== -1) return `${length} ft.`;
      else return "N/A";
    },
    formatNumbers(number) {
      if (number !== -1) return number;
      else return "N/A";
    },
    loadData(ctx) {
      if (!this.parkNotSelected) {
        this.loading = true;
        let paging = {
          pageNumber: ctx.currentPage,
          itemsPerPage: ctx.perPage,
          sortField: ctx.sortBy,
          sortDescending: ctx.sortDesc
        };
        let searchCriteria = {
          locationId: this.search.selectedPark.id,
          spotName: this.search.spotName ? this.search.spotName : "",
          areaIds: this.search.selectedAreas
            ? this.search.selectedAreas.map(x => x.id)
            : [],
          loopIds: this.search.selectedLoops
            ? this.search.selectedLoops.map(x => x.id)
            : [],
          productClassificationIds: this.search.selectedProductClassifications
            ? this.search.selectedProductClassifications.map(x => x.id)
            : [],
          spotTypeIds: this.search.selectedSpotTypes
            ? this.search.selectedSpotTypes.map(x => x.id)
            : []
        };
        const spotService = new AdminSpotService(this.tenantId);
        return spotService
          .search(searchCriteria, paging)
          .then(response => {
            if (response?.statusCode === "Success") {
              this.currentPage = response.searchResults.pageNumber;
              this.totalRows = response.searchResults.totalItems;
              this.perPageDisplay = response.searchResults.itemsPerPage;
              const result = response.searchResults.data;
              this.spots = result;
              return result;
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
              window.scrollTo(0, 0);
              return this.spots;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    reset() {
      this.$store.commit("admin/setSpotSearchParams", null);
      this.search = {};
      this.spots = [];
      this.$nextTick(() => this.$refs.spotSearchForm.reset());
    },
    resetOtherFields(park) {
      this.search = { selectedPark: park };
      this.loops = this.areas = this.productClassifications = this.spotTypes = [];
    },
    addSpot() {
      this.$router.push(`/admin/add-new-spot`).catch(() => {});
    },
    openedParks() {
      if (this.parks.length == 0) {
        this.loadingParks = true;
        this.getParks();
      }
    },
    async getParks() {
      const lookupService = new AdminLookupService(this.tenantId);
      const locations = await lookupService.getLocations();
      this.parks = locations.filter(loc =>
        this.checkLocationPermission("ParkManagementSpotSearch", loc.id)
      );
      this.loadingParks = false;
    },
    openedLoops() {
      if (this.loops.length == 0) {
        this.loadingLoops = true;
        this.getLoops(this.search.selectedPark.id);
      }
    },
    async getLoops(id) {
      const lookupService = new AdminLookupService(this.tenantId);
      const response = await lookupService.getLoopsForLocation(id);
      this.loops = response;
      this.loadingLoops = false;
    },
    openedAreas() {
      if (this.areas.length == 0) {
        this.loadingAreas = true;
        this.getAreas(this.search.selectedPark.id);
      }
    },
    async getAreas(id) {
      const lookupService = new AdminLookupService(this.tenantId);
      const response = await lookupService.getAreasForLocation(id);
      this.areas = response;
      this.loadingAreas = false;
    },
    openedProductClassifications() {
      if (this.productClassifications.length == 0) {
        this.loadingProductClassifications = true;
        this.getProductClassifications(this.search.selectedPark.id);
      }
    },
    async getProductClassifications(id) {
      const lookupService = new AdminLookupService(this.tenantId);
      const response = await lookupService.getProductClassificationsForLocation(
        id
      );
      this.productClassifications = response.map(x => {
        return {
          ...x,
          name: `${x.name} - P${(x.id + "").padStart(3, "0")}`
        };
      });
      this.loadingProductClassifications = false;
    },
    openedSpotTypes() {
      if (this.spotTypes.length == 0) {
        this.loadingSpotTypes = true;
        this.getSpotTypes([this.search.selectedPark.id]);
      }
    },
    async getSpotTypes(id) {
      const lookupService = new AdminLookupService(this.tenantId);
      const response = await lookupService.getSpotTypesForLocation(id);
      this.spotTypes = response;
      this.loadingSpotTypes = false;
    }
  },
  mounted() {
    if (this.searchParams) {
      this.search = this.searchParams;
      this.searchSpots(false);
    }
    this.$refs.spotSearchPark.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.spotSearchArea.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.spotSearchLoop.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.spotSearchProductClassifications.$refs.search.setAttribute(
      "autocomplete",
      "off"
    );
    this.$refs.spotSearchSpotType.$refs.search.setAttribute(
      "autocomplete",
      "off"
    );
  }
};
</script>
