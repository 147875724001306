import AdminSearchApi from "@/generatedapiclients/src/api/AdminSearchApi.js";
import AdminSpotApi from "@/generatedapiclients/src/api/AdminSpotApi.js";

export default class AdminSpotService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminSearchApi = new AdminSearchApi();
    this._adminSpotApi = new AdminSpotApi();
  }
  async search(searchCriteria, paging) {
    return this._adminSearchApi.v1TenantTenantIdAdminAdminSearchSpotsGet(
      this._tenantId,
      {
        ...searchCriteria,
        ...paging
      }
    );
  }

  getSpotImages(spotId) {
    return this._adminSpotApi.v1TenantTenantIdAdminAdminSpotSpotIdImagesGet(
      spotId,
      this._tenantId
    );
  }

  async addSpotImage(spotId, title, file) {
    const response = await this._adminSpotApi.v1TenantTenantIdAdminAdminSpotSpotIdImagePost(
      spotId,
      this._tenantId,
      {
        title: title,
        formFile: file
      }
    );

    return response;
  }

  async getSpot(spotId) {
    const response = await this._adminSpotApi.v1TenantTenantIdAdminAdminSpotSpotGet(
      this._tenantId,
      {
        spotId
      }
    );
    return response;
  }

  async deleteSpotImage(spotId, imageId) {
    const response = await this._adminSpotApi.v1TenantTenantIdAdminAdminSpotSpotIdDeleteImageDelete(
      this._tenantId,
      spotId,
      {
        imageId: imageId
      }
    );
    return response;
  }

  async saveSpot(spot) {
    if (spot.id) {
      const response = await this._adminSpotApi.v1TenantTenantIdAdminAdminSpotSpotPut(
        this._tenantId,
        {
          spotRequest: { ...spot }
        }
      );
      return response;
    }

    const response = await this._adminSpotApi.v1TenantTenantIdAdminAdminSpotSpotPost(
      this._tenantId,
      {
        spotRequest: { ...spot }
      }
    );
    return response;
  }
  async getSpotTypes() {
    const response = await this._adminSpotApi.v1TenantTenantIdAdminAdminSpotSpotTypesGet(
      this._tenantId
    );
    return response;
  }
}
